import { FunctionComponent } from 'react';
import HomePage from './pages/home/Home.page';

const App: FunctionComponent<{}> = ({}) => {
    return (
        <HomePage />
    )    
};

export default App;
