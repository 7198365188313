import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import { SiEtsy, SiInstagram } from 'react-icons/si'
import './Container.component.css';

const ContainerComponent: FunctionComponent<{
    containerStyle?: CSS.Properties;
}> = ({
    containerStyle
}) => {

    const instagramUrl: string = 'https://www.instagram.com/outrefair/';
    const etsyUrl: string = 'https://www.etsy.com/OutreFair/';

    const handleInstagramClick = () => {
        window.open(instagramUrl);
    }

    const handleEtsyClick = () => {
        window.open(etsyUrl);
    }

    return (
        <div style={containerStyle} className='container-component'>
            <p className='title-text'>
                It's nearly here! Check out Outré Fair products:
            </p>    
            {/* <div className='link-container title-text' onClick={handleEtsyClick}>
                <p>Etsy</p>
                <SiEtsy
                    className='icon'
                    size={18}
                />
            </div> */}
            <div className='link-container title-text' onClick={handleInstagramClick}>
                <p>Instagram</p>
                <SiInstagram
                    className='icon'
                    size={18}
                />
            </div>

        </div>
    )
};

export default ContainerComponent;