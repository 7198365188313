import React, { FunctionComponent } from 'react';
import ContainerComponent from '../../components/container/Container.component';

import './Home.page.css';

const HomePage: FunctionComponent<{}> = ({}) => {
    return (
        <div className='home-page'>
            <img src={require('../../assets/coming_soon_banner.png')} className='banner'/>
            <ContainerComponent containerStyle={{
                maxWidth: '680px'
            }}/>
        </div>
    )    
};

export default HomePage;